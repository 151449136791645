<template>

  <div>

    <group-list-add-new
      :is-add-new-group-sidebar-active.sync="isAddNewGroupSidebarActive"
      :balance-options="balanceOptions"
      :group-options="groupOptions"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row class="align-items-end">

          <!-- Per Page -->
          <b-col
            cols="12"
            md="7"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Показано</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>записів</label>
          </b-col>

          <!-- <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <groups-list-filters
              :balance-filter.sync="balanceFilter"
              :group-filter.sync="groupFilter"
              :status-filter.sync="statusFilter"
              :balance-options="balanceOptions"
              :group-options="groupOptions"
              :status-options="statusOptions"
            />
          </b-col> -->

          <!-- Search -->
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Пошук..."
              />
              <b-button
                variant="primary"
                @click="isAddNewGroupSidebarActive = true"
              >
                <span class="text-nowrap">Додати групу</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchGroups"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Відповідних записів не знайдено"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Group -->
        <template #cell(title)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.title)"
                :variant="`light-${resolveUserBalanceVariant(data.item.balanceType)}`"
                :to="{ name: 'apps-groups-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-groups-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.title }}
            </b-link>
            <!-- <small class="text-muted">@{{ data.item.username }}</small> -->
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-groups-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Подробиці</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-groups-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Редагувати</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteGroup(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Видалити</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Показано {{ dataMeta.from }} до {{ dataMeta.to }} з {{ dataMeta.of }} записів</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalGroups"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBPopover
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import GroupsListFilters from './GroupsListFilters.vue'
import useGroupsList from './useGroupsList'
import groupStoreModule from '../groupStoreModule'
import GroupListAddNew from './GroupListAddNew.vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    GroupsListFilters,
    GroupListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  setup(props, { emit }) {

    const toast = useToast()

    const DRIVER_APP_STORE_MODULE_NAME = 'app-group'

    // Register module
    if (!store.hasModule(DRIVER_APP_STORE_MODULE_NAME)) store.registerModule(DRIVER_APP_STORE_MODULE_NAME, groupStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DRIVER_APP_STORE_MODULE_NAME)) store.unregisterModule(DRIVER_APP_STORE_MODULE_NAME)
    })

    const isAddNewGroupSidebarActive = ref(false)
    const isAddNewGroupCardSidebarActive = ref(false)

    const balanceOptions = [
      { label: 'Баланс', value: '1' },
      { label: 'Ліміт', value: '2' },
    ]

    const groupOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
    ]

    const statusOptions = [
      { value: 'WAITING_FOR_USER_REGISTRATION', label: 'Очікується реєстрація користувача' },
      { value: 'ACCOUNT_PROCESSING', label: 'Обробка' },
      { value: 'WAITING_DRIVER_PIN_CONFIRMATION', label: 'Очікується підтвердження PIN-коду' },
      { value: 'CONFIRMED', label: 'Підтверджено' },
      { value: 'PENDING_DATEX_CREATION', label: 'Очікується створення DATEX' },
      { value: 'PREPARED', label: 'Готовий' },
      { value: 'ACTIVE', label: 'Активний' },
      { value: 'BLOCKED', label: 'Заблокований' },
    ]

    const {
      fetchGroups,
      tableColumns,
      perPage,
      currentPage,
      totalGroups,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserBalanceVariant,
      resolveUserBalance,
      resolveUserStatusVariant,
      resolveUserStatus,

      // Extra Filters
      balanceFilter,
      groupFilter,
      statusFilter,
    } = useGroupsList()

    const deleteGroup = (id) => {

      if (confirm("Ви впевнені?")) {

        store.dispatch('app-group/deleteGroup', id)
          .then(() => {
            refetchData()
          }).catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Не вдалось видалити групу',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
            return false
          })
      }
    }

    return {

      // Sidebar
      isAddNewGroupSidebarActive,
      isAddNewGroupCardSidebarActive,

      fetchGroups,
      tableColumns,
      perPage,
      currentPage,
      totalGroups,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserBalanceVariant,
      resolveUserBalance,
      resolveUserStatusVariant,
      resolveUserStatus,

      balanceOptions,
      groupOptions,
      statusOptions,

      // Extra Filters
      balanceFilter,
      groupFilter,
      statusFilter,
      toast,

      deleteGroup,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.media {
  align-items: center;
}

.group-cards {
  display: flex;
  align-items: flex-start;
}

.group-card-wrapper {
}

.group-card {
  cursor: pointer;
  width: 60px;
  // height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 10px;
  margin-right: 10px;
}

.group-card.empty {
  height: 37px;
  border: 1px solid var(--primary);
  border-radius: 5px;
  color: var(--primary);
  font-size: 14px;
  font-weight: 800;
}

// .group-card:last-child {
//   margin-right: 0;
// }

.group-card-image {
  width: 60px;
  height: 37px;
  border-radius: 5px;
  object-fit: cover;
}

[dir] .table td {
  padding: 1rem 2rem;
}

.group-card-balance {
  margin-top: 3px;
}

</style>
